/*==== DEVICE RESPONSIVE FIX ====*/
function fixDeviceResponsive() {
  document.querySelectorAll('.device-macbook-pro').forEach(function (e) {
    var device = e;
    var $device = $(e);
    $device.css('transform', 'scale(' + $device.parent().width() / 740 + ')');
    $device.parent().height(device.getBoundingClientRect().height);
  })
}

fixDeviceResponsive();

/*==== CUSTOM TABS ====*/
function manualChangeTab(element) {
  var tabId = $(element).attr("data-tab");

  $(element).siblings("li").removeClass("is-active");
  $(element).closest(".navigation-tabs").children(".navtab-content").removeClass("is-active");
  $(element).addClass("is-active");
  $("#" + tabId).addClass("is-active");
}

(function () {
  var counter = [];
  var autochange = [];

  document.querySelectorAll('.custom-auto-change').forEach(function (e, i) {
    var elements = e.querySelectorAll('li');

    counter[i] = 0;

    autochange[i] = setInterval(function () {
      manualChangeTab(elements[counter[i]]);
      counter[i]++;

      if (counter[i] == elements.length) {
        counter[i] = 0;
      }
    }, 8000);

    $(e).on("click", function () {
      clearInterval(autochange[i]);
    });
  })
})();

/*==== LISTENERS ====*/
//Da fixare solo per resize orizzontale;
$(window).on('resize', function () {
  /*==== DEVICE RESPONSIVE FIX ====*/
  fixDeviceResponsive();
});

 // Smooth scrolling
$('a.js-smooth').on('click', function () {
 $('html, body').animate({
    scrollTop: $($(this).attr('href')).offset().top
  }, 500);
});

$('.navbar-item').on('click', function () {
  if ($(window).width() < 1024) {
    $('.menu-toggle').trigger('click');
  }
});

$('[data-link]').on('click', function() {
  const link = $(this).attr('data-link');
  window.open(link, '_blank');
})


function checkSubscribeForm() {
  let re = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/;

  if (!(re.test($("#email").val()))) {
    $("#result").css('color', 'red');
    $("#result").empty().append("Please enter a valid email address");
    $("#email").trigger('focus');
    return false;
  }

  return true;
}

function submitSubscribeForm() {
  let successMessage = 'Thank you for your registration. Please check your email to confirm.';
  let data = $('#subscribeform').serialize();

  $.ajax({
    type: 'POST',
    data: data,
    url: 'https://biz.8pay.network/lists/?p=subscribe&id=30&acao=1',
    dataType: 'html',
    success: function (data, status, request) {
      $("#result").css('color', 'green');
      $("#result").empty().append(successMessage);
      $('#email').val('');
    },
    error: function (request, status, error) {
      $("#result").css('color', 'red');
      $("#result").empty().append('Sorry, we were unable to process your subscription.');
    }
  });
}
